<!-- =========================================================================================
    File Name: movieCard.vue
    Description: Movie page card component
    ----------------------------------------------------------------------------------------
    Item Name: KOZO Admin - VueJS Dashboard  
    Author:  
    Author URL:  
========================================================================================== -->

<template>
  <div>
    <!-- GRID VIEW -->
    <div v-if="GridView" class="vx-row mt-10">
      <div
        class="vx-col w-full md:w-1/4 mb-base"
        v-for="(project, projectIndex) in projects"
        :key="projectIndex"
      >
        <div class="grid-view">
          <vx-card class="overflow-hidden">
            <div class="mb-3">
              <img :src="imagePath+'?token='+tempToken" class="img-fluid w-100" alt />
            </div>
            <div class="movie-info">
              <h5 class="font-weight-bold">{{project.project_name}}</h5>
              <p class="line-clamp">{{project.project_details}}</p>
            </div>

            <ul class="list-inline text-right">
              <li class="list-inline-item font-semibold">
                <router-link to="/verificationList">
                  <feather-icon icon="ListIcon" svgClasses="h-7 w-7" />
                </router-link>
              </li>
              <li class="list-inline-item font-semibold">
                <router-link
                  :to="{ name: 'editProjectView', params: { projectID: project.project_id }}"
                >
                  <feather-icon icon="EditIcon" svgClasses="h-7 w-7" />
                </router-link>
              </li>
              <li class="list-inline-item font-semibold">
                <router-link to>
                  <feather-icon icon="ArchiveIcon" svgClasses="h-7 w-7" />
                </router-link>
              </li>
            </ul>
          </vx-card>
        </div>
      </div>
    </div>
    <!-- LIST VIEW -->
    <div class="vx-row" v-if="ListView">
      <div class="vx-col w-full mb-base">
        <div class="list-view" id="data-list-list-view">
          <vs-table ref="table" pagination :max-items="4" :data="project">
            <template slot-scope="{projects}">
              <tbody>
                <vs-tr :data="project" :key="indextr" v-for="(project, indextr) in projects">
                  <vs-td>
                    <!-- <img :src="imagePath+'?token='+tempToken" class alt /> -->
                    <span>{{ indextr+1 }}</span>
                  </vs-td>

                  <vs-td>
                    <h5 class="font-weight-bold">{{project.project_name}}</h5>
                    <p>{{project.project_details}}</p>
                  </vs-td>
                </vs-tr>
              </tbody>
            </template>
          </vs-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import projectService from "@/services/projectService.js";

export default {
  data() {
    return {
      projects: [],
      tempToken:window.localStorage.getItem("TempToken"),
    };
  },
  props: {
    gridView: {
      type: Boolean,
      default: true,
    },
    listView: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
    },
    // chartData: {
    //   type: Object,
    //   // required: true,
    //   default: {}
    // },
    color: {
      type: String,
      default: "primary",
    },
    chartType: {
      type: String,
      default: "line-chart",
    },
    type: {
      type: String,
      default: "line",
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "",
    },
    deptName: {
      type: String,
      default: "",
    },
    deptNum: {
      type: String,
      default: "0",
    },
    imagePath: {
      type: String,
      default: "",
    },
  },
  watch: {
    themePrimaryColor() {
      this.$refs.apexChart.updateOptions({
        theme: { monochrome: { color: this.getHex() } },
      });
    },
  },
  computed: {
    Items() {
      return this.items;
    },
    themePrimaryColor() {
      return this.$store.state.themePrimaryColor;
    },
    GridView() {
      return this.gridView;
    },
    ListView() {
      return this.listView;
    },
  },
  methods: {
    getProjectsList: function() {
      let payload = {
        limit: this.paginationPageSize,
      };
      projectService
        .getAllProjects(payload)
        .then(response => {
          const { data } = response;
          if (data.Status == false) {
          } else {
            this.projects = data.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    getHex() {
      let rgb = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue(`--vs-${this.color}`);
      rgb = rgb.split(",");
      return (
        "#" +
        (
          (1 << 24) +
          (Number(rgb[0]) << 16) +
          (Number(rgb[1]) << 8) +
          Number(rgb[2])
        )
          .toString(16)
          .slice(1)
      );
    },
  },
  components: {
    VueApexCharts,
  },
  created() {
    // if (this.type == "area") {
    //   this.chartData && this.chartData["chartOptions"]
    //     ? (this.chartData.chartOptions["theme"] = {
    //         monochrome: {
    //           enabled: true,
    //           color: this.getHex(this.color),
    //           shadeTo: "light",
    //           shadeIntensity: 0.65
    //         }
    //       })
    //     : "";
    // }
  },
  mounted() {
    this.getProjectsList();
  },
};
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>